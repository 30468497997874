import { interfaces } from '@jarvis/shell-commons';
import { PromiseReturnType } from './typeHandlers';
import { useToast } from '@veneer/core';
import { MfePropsType } from './mfeProps';

export type InterfacesType = Omit<typeof interfaces, '_shell'>;

export type TranslatorFunctionType = PromiseReturnType<
  InterfacesType['v1']['localization']['createTranslatorFunction']
>;

export enum Stack {
  dev,
  pie,
  stage,
  prod
}

export type ShellProps = {
  interfaces: InterfacesType;
};

export type ShellRootComponentProps = {
  useToast: typeof useToast;
} & MfePropsType;
