import tokens from '@veneer/tokens';
import projectNames from '../../contexts/dependencyManager/configs/projectNames';
import { lgMax } from './styles';

function getNumericValue(screenSize: string) {
  return parseInt(screenSize.replace(/\D/g, ''));
}

function generateQuery(element: string) {
  return `[class*="${element}-${projectNames.sanitizedPackageName}"]`;
}

export function getScreenSize(): string {
  const windowWidth = window.innerWidth;
  if (windowWidth <= getNumericValue(tokens.layout.xsMax)) {
    return 'small';
  } else if (windowWidth <= getNumericValue(lgMax)) {
    return 'medium';
  } else {
    return 'large';
  }
}

export function isLargeScreen(): boolean {
  return getScreenSize() === 'large';
}

export function generateCardsQuery(): string {
  return generateQuery('StyledCard');
}

export function hasWideCard(cards: HTMLElement[]): boolean {
  const maxOffsetWidth = 270; // [1140 max-width - (3 * 20) gap]/4
  let hasWideCard = false;
  cards.forEach((card) => {
    if (card.offsetWidth > maxOffsetWidth) {
      hasWideCard = true;
      return;
    }
  });
  return hasWideCard;
}
