import tokens from '@veneer/tokens';
import styled from 'styled-components';

interface FrameProps {
  columns: string;
}

const minCardWidth = '249px';
const minGridWidth = `minmax(${minCardWidth}, 1fr)`;

export const defaultGrid = `repeat(4, ${minGridWidth})`;
export const ajustedGrid = `repeat(2, ${minGridWidth})`;

export const xlMin = '1400px';
export const lgMax = '1399px';

export const Frame = styled.div<FrameProps>`
  display: grid;
  align-items: stretch;
  align-content: flex-start;
  align-self: stretch;
  justify-content: center;
  gap: ${tokens.layout.size5};
  grid-template-columns: repeat(auto-fill, ${minGridWidth});
  width: 100%;
  box-sizing: border-box;
  flex: 1;

  @media (min-width: ${xlMin}) {
    grid-template-columns: ${(props) => props.columns};
    white-space: nowrap;
  }

  @media (max-width: ${lgMax}) and (min-width: ${tokens.layout.smMin}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${tokens.layout.xsMax}) {
    grid-template-columns: repeat(1, 1fr);
  }
}`;
