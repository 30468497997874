import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import FrameCards from 'src/components/FrameCards';
import { Header } from './styles';

const Help = () => {
  const { translate } = useDependencyManagerContext();

  return (
    <>
      <Header data-testid="header">
        <h4>{`${translate('menu.support', 'Help & support')}`}</h4>
        <p>
          {`${translate(
            'support.getSupport',
            'Get support for your HP account and devices'
          )}`}
        </p>
      </Header>
      <FrameCards />
    </>
  );
};
export default Help;
