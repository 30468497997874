import React, { useEffect } from 'react';
import { useWebcomponent } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import Help from 'src/pages/Help';
import {
  HelpAndSupportScreenDisplayed,
  publishEvent
} from 'src/utils/analytics';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlagsEnum } from 'src/utils/enums';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import * as S from './styles';

// This file is your main component, it's the one that will be rendered by default.
// We recommend to create small components inside the components folder
// and import them here based on your need to make this file easier to understand.
const AppComponent: React.FC<T.AppPropsType> = () => {
  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);

  const showQualtricsSurvey = qualtricsSurvey && !isQualtricsSurveyLoading;

  useWebcomponent({
    url: showQualtricsSurvey ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  return (
    <S.Container data-testid="support-page">
      <Help />
    </S.Container>
  );
};

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  useEffect(() => {
    publishEvent(HelpAndSupportScreenDisplayed);
  }, []);

  return (
    <DependencyManagerProvider>
      <AppComponent {...props} />
    </DependencyManagerProvider>
  );
};

export default App;
