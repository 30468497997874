import {
  IconDashboardApplication,
  IconRearrange,
  IconSearch,
  IconShoppingCart
} from '@veneer/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  DeviceSupportClicked,
  HPStoreSupportClicked,
  SubscriptionsAndServicesClicked,
  TroubleshootAndFixDeviceClicked
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import FrameCard from './FrameCardTemplate';
import { Frame, defaultGrid, ajustedGrid } from './styles';
import { hasWideCard, generateCardsQuery, isLargeScreen } from './utils';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

const FrameCards = () => {
  const { translate } = useDependencyManagerContext();
  const frameRef = useRef(null);
  const [columns, setColumns] = useState(defaultGrid);

  useEffect(() => {
    const updateColumns = () => {
      if (isLargeScreen()) {
        if (frameRef.current) {
          const cardsQuery = generateCardsQuery();
          const cards = frameRef.current.querySelectorAll(cardsQuery);
          const shouldAdjustGrid = hasWideCard(cards);

          if (shouldAdjustGrid) {
            setColumns(ajustedGrid);
          } else {
            setColumns(defaultGrid);
          }
        }
      }
    };
    updateColumns();
    window.addEventListener('resize', updateColumns);

    return () => {
      window.removeEventListener('resize', updateColumns);
    };
  }, []);

  const Cards = [
    {
      url: URLS.HPStoreSupport,
      analyticsEvent: HPStoreSupportClicked,
      icon: <IconShoppingCart size={32} />,
      text: translate('support.hpStoreSupport', 'HP Store support')
    },
    {
      url: URLS.DeviceSupport,
      analyticsEvent: DeviceSupportClicked,
      icon: <IconDashboardApplication size={32} />,
      text: translate(
        'common.deviceSupportDashboard',
        'Device support dashboard'
      )
    },
    {
      url: URLS.TroubleshootAndFixDevice,
      analyticsEvent: TroubleshootAndFixDeviceClicked,
      icon: <IconSearch size={32} />,
      text: translate(
        'support.troubleshootDevice',
        'Troubleshoot & fix a device'
      )
    },
    {
      url: URLS.SubscriptionAndServicesSupport,
      analyticsEvent: SubscriptionsAndServicesClicked,
      icon: <IconRearrange size={32} />,
      text: translate(
        'support.subscriptionSupport',
        'Subscription & services support'
      )
    }
  ];

  return (
    <Frame
      data-testid="frame-cards"
      ref={frameRef}
      columns={columns}
    >
      {Cards.map((card) => (
        <FrameCard
          key={card.text}
          card={card}
        />
      ))}
    </Frame>
  );
};

export default FrameCards;
