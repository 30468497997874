import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: inline-block;
  padding: ${tokens.layout.size4};
  min-width: min-content;
  width: auto;

  && {
    background-color: ${tokens.color.gray0};
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
      color: ${tokens.color.black};
    }
  }

  @media (min-width: 1400px) {
    min-width: min-content;
    white-space: nowrap;
  }

  @media (max-width: ${tokens.layout.smMax}) {
    width: 100%;
  }
`;

export const CardContent = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.layout.size2};
  padding: ${tokens.layout.size2} 0 ${tokens.layout.size2} 0;
  flex: 1 0 0;
  text-align: center;
  justify-content: center;
`;
