import { Stack } from 'src/types/shell';

export const QUALTRICS_SURVEY_PROD_URL =
  'https://znaid9q7g0kclquju-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_aid9q7g0kClquJU';
export const QUALTRICS_SURVEY_TEST_URL =
  'https://zncuabwr7p241aiia-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_cuaBwr7P241aIia';

export const getQualtricsSurveyUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod
    ? QUALTRICS_SURVEY_PROD_URL
    : QUALTRICS_SURVEY_TEST_URL;
};

export const URLS = {
  HPStoreSupport: 'https://www.hp.com/us-en/shop/cv/customerservice#CS',
  DeviceSupport: 'https://support.hp.com/us-en/dashboard',
  TroubleshootAndFixDevice: 'https://support.hp.com/us-en',
  SubscriptionAndServicesSupport:
    'https://www.hp.com/us-en/shop/cv/customerservice#Services'
};
