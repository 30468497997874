import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';
import { URLS } from './urls';

const activity = 'UnifiedAcctMgmt-v01';
const screenPath = '/ReactMyAccountSupport/';
const screenName = 'HelpAndSupport';
const eventDetailVersion = '1.5.0';

export type AnalyticsEventPropTypes = {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlName?: string;
  controlDetail?: string;
  version: string;
};

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
): void => {
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_TILE_CLICKED: 'ControlTileClicked'
};

export const HelpAndSupportScreenDisplayed = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const HPStoreSupportClicked = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'HPStoreSupport',
  controlDetail: URLS.HPStoreSupport,
  version: eventDetailVersion
};

export const DeviceSupportClicked = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'DeviceSupportDashboard',
  controlDetail: URLS.DeviceSupport,
  version: eventDetailVersion
};

export const TroubleshootAndFixDeviceClicked = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'TroubleshootAndFixDevice',
  controlDetail: URLS.TroubleshootAndFixDevice,
  version: eventDetailVersion
};

export const SubscriptionsAndServicesClicked = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'SubscriptionAndServicesSupport',
  controlDetail: URLS.SubscriptionAndServicesSupport,
  version: eventDetailVersion
};
