import React from 'react';
import { AnalyticsEventPropTypes, publishEvent } from 'src/utils/analytics';
import { CardContent, StyledCard } from './styles';

interface IFrameCardProps {
  url: string;
  analyticsEvent: AnalyticsEventPropTypes;
  icon?: React.ReactNode;
  text?: string;
}

interface IFrameCardTemplateProps {
  card: IFrameCardProps;
}

const FrameCard = ({ card }: IFrameCardTemplateProps) => {
  const handleClick = () => {
    publishEvent(card.analyticsEvent);
    window.open(card.url, '_blank');
  };

  return (
    <StyledCard
      hoverable
      appearance="outlined"
      onClick={handleClick}
      data-testid="frame-card"
      content={
        <CardContent className="body">
          {card.icon}
          {card.text}
        </CardContent>
      }
    />
  );
};

export default FrameCard;
